
.line {
  stroke-width: 2;
  fill: none;
}

.axis path {
  stroke: black;
  font-family: 'Muli-Regular';
}

.text {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
}

.itemValue {
  font-family: 'Muli-Regular';
  font-size: 14px;
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.6;
  shape-rendering: crispEdges
}
.grid path {
  stroke-width: 0;
}
.title-text {
  font-size: 12px;
}
